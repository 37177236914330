import request, { setPBName } from 'UTILS/Axios'
import { KEY } from 'UTILS/utils'
import jsonName from '../pb/index.json'
import { INamespace } from 'protobufjs'
import { bikiiDomainMap } from 'UTILS/constants'
import { yunpb } from '../protobuf'
const apiUrl = bikiiDomainMap[process.env.API_ENV]

// promise写法
setPBName(jsonName as INamespace)

/**
 * PC官网游戏分享
 */
export const apiUserShareGame = (data: yunpb.IUserShareGameReq): Promise<ExcludeNonNull<yunpb.IUserShareGameRes>> => {
  return request({
    url: apiUrl,
    reqDesction: 'UserShareGameReq',
    resDesction: 'UserShareGameRes',
    funcName: 'UserShareGame',
    serverName: 'bikii.web.WebExtObj',
    token: KEY,
    data,
  })
}
