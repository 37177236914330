const getEnv = () => {
  const localEnv = localStorage.getItem('env')
  if (localEnv) {
    return localEnv
  }
  if (location.href.indexOf('localhost') !== -1 || /\d+\.\d+\.\d+\.\d+/.test(location.href)) {
    return 'Debug'
  } else if (location.href.indexOf('alpha') > -1) {
    return 'Test'
  }
  return 'Product'
}

export const domainMap: Record<string, string> = {
  // Debug: 'https://alpha-api.caijiyouxi.com',
  Debug: 'https://pre-api.caiji188.com/proxyyun',
  Test: 'https://pre-api.caiji188.com/proxyyun',
  Product: 'https://api.caijiyouxi.com/proxyyun',
  development: 'https://pre-api.caiji188.com/proxyyun',
  pre: 'https://pre-api.caiji188.com/proxyyun',
  production: 'https://api.caijiyouxi.com/proxyyun',
}

/**
 * 海外版菜鸡接口地址，测试服暂时无域名
 * Last modified: 2020年7月23日
 */

export const chikiiDomainMap = {
  Debug: 'https://api-beta.chikiigame.com/proxyyun',
  Test: 'https://api-beta.chikiigame.com/proxyyun',
  Product: 'https://api-prod-v2.chikiigame.com/proxyyun',
  development: 'https://api-beta.chikiigame.com/proxyyun',
  pre: 'https://api-beta.chikiigame.com/proxyyun',
  production: 'https://api-prod-v2.chikiigame.com/proxyyun',
}
/**
 * 海外版菜鸡接口地址，测试服暂时无域名
 * Last modified: 2023年8月15日
 */

export const bikiiDomainMap = {
  Debug: 'https://api-beta.bikiigame.com:80/proxyyun',
  Test: 'https://api-beta.bikiigame.com:80/proxyyun',
  Product: 'https://api-prod-v2.bikiigame.com/proxyyun',
  development: 'https://api-beta.bikiigame.com:80/proxyyun',
  pre: 'https://api-beta.bikiigame.com:80/proxyyun',
  production: 'https://api-prod-v2.bikiigame.com/proxyyun',
}
/*
 * mogu 接口地址
 */
export const moguDomainMap = {
  Debug: 'https://api-beta.gamemogu.com/proxyyun',
  Test: 'https://api-beta.gamemogu.com/proxyyun',
  Product: 'https://api-prod.gamemogu.com/proxyyun',
  development: 'https://api-beta.gamemogu.com/proxyyun',
  pre: 'https://api-beta.gamemogu.com/proxyyun',
  production: 'https://api-prod.gamemogu.com/proxyyun',
}
/*
 * joysak 接口地址
 */
export const joysakDomainMap = {
  Debug: 'https://api-beta.joysak.com/proxyyun',
  Test: 'https://api-beta.joysak.com/proxyyun',
  Product: 'https://api-prod-v2.joysak.com/proxyyun',
  development: 'https://api-beta.joysak.com/proxyyun',
  pre: 'https://api-beta.joysak.com/proxyyun',
  production: 'https://api-prod-v2.joysak.com/proxyyun',
}
/**
 * 哈基米接口地址
 */
export const hajimiDomainMap = {
  Debug: 'https://pre-api.hajimicloud.com/proxyyun',
  Test: 'https://pre-api.hajimicloud.com/proxyyun',
  Product: 'https://api.hajimicloud.com/proxyyun',
  development: 'https://pre-api.hajimicloud.com/proxyyun',
  pre: 'https://pre-api.hajimicloud.com/proxyyun',
  production: 'https://api.hajimicloud.com/proxyyun',
}

// 开黑云接口地址
export const mitaDomainMap = {
  Debug: 'https://pre-api.kaiheicloud.com/proxyyun',
  Test: 'https://pre-api.kaiheicloud.com/proxyyun',
  Product: 'https://api.kaiheicloud.com/proxyyun',
  development: 'https://pre-api.kaiheicloud.com/proxyyun',
  pre: 'https://pre-api.kaiheicloud.com/proxyyun',
  production: 'https://api.kaiheicloud.com/proxyyun',
}

// 会画接口地址
export const huihuaDomainMap = {
  Debug: 'https://pre-api.huihua668.com/proxyyun',
  Test: 'https://pre-api.huihua668.com/proxyyun',
  Product: 'https://api.huihua668.com/proxyyun',
  development: 'https://pre-api.huihua668.com/proxyyun',
  pre: 'https://pre-api.huihua668.com/proxyyun',
  production: 'https://api.huihua668.com/proxyyun',
}

const ua = window.navigator.userAgent
const uaLowerCase = ua.toLowerCase()

export const ENV = getEnv()

export const CAIJI_API_URL = domainMap[ENV]

// ipad13.3系统用这个会有问题
// export const isIOS = !!(ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/))
export const isIOS = !!ua.match(/Mac OS X/)

export const isAndroid = ua.indexOf('Android') > -1 || ua.indexOf('Linux') > -1

export const isInApp =
  uaLowerCase.indexOf('caiji') > -1 ||
  uaLowerCase.indexOf('caijinetgame') > -1 ||
  uaLowerCase.indexOf('chikii') > -1 ||
  uaLowerCase.indexOf('bikii') > -1 ||
  uaLowerCase.indexOf('kaiheiyun') > -1 ||
  uaLowerCase.indexOf('kaihei') > -1 ||
  uaLowerCase.indexOf('mitayun') > -1 ||
  uaLowerCase.indexOf('hajimi') > -1 ||
  uaLowerCase.indexOf('joysak') > -1 ||
  uaLowerCase.indexOf('mogu') > -1

export const isWechatOrQQOrWeibo = uaLowerCase.indexOf('micromessenger') >= 0 || uaLowerCase.indexOf(' qq') > -1 || uaLowerCase.indexOf('weibo') >= 0

export const webpSupport = !![].map && document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') === 0

export const iosAppStore = 'https://apps.apple.com/cn/app/%E8%8F%9C%E6%9C%BA/id1486556047'

export const isChikii = uaLowerCase.indexOf('chikii') > -1

export const isCaiJi = uaLowerCase.indexOf('caiji') > -1

export const isKaihei = /kaihei/i.test(uaLowerCase)
