import { getQueryString } from 'UTILS/utils'
import { apiUserShareGame } from './api'
import { ENV } from 'ASSETS/utils/constants'

import '@/style/index.scss'

const gameShare = getQueryString('game_share')
const form = document.querySelector('.form') as HTMLElement
// const content = document.querySelector('.content') as HTMLElement
const back = document.querySelector('.icon-back') as HTMLElement
const gameShareBtn = document.querySelector('.game-share') as HTMLElement
const googlePlayBtn = document.querySelector('.google-play') as HTMLElement
const appStoreBtn = document.querySelector('.app-store') as HTMLElement
const requiredInput = document.querySelectorAll<HTMLInputElement>('.required-input')
const submitBtn = document.querySelector('.submit-btn') as Element
const textareaVal = document.querySelector('.textarea') as HTMLInputElement
const overlay = document.querySelector('.overlay') as HTMLElement

if (gameShare) {
  // content.style.display = 'none'
  form.style.display = 'block'
}

googlePlayBtn.addEventListener('click', () => {
  location.href = 'https://play.google.com/store/apps/details?id=com.bikii.game'
})
appStoreBtn.addEventListener('click', () => {
  location.href = 'https://apps.apple.com/us/app/bikii-bee-ai/id6475163903'
})

// 返回按钮
back.addEventListener('click', () => {
  // content.style.display = 'block'
  form.style.display = 'none'
  overlay.style.display = 'none'
})

// 点击游戏分享按钮
gameShareBtn.addEventListener('click', () => {
  // content.style.display = 'none'
  checkInputs()
  overlay.style.display = 'block'
  form.style.display = 'block'
  // @ts-ignore
  window._hmt.push(['_trackEvent', 'bikii_home_gameShare_click', 'click'])
})

// 提示
let toastTimer: NodeJS.Timeout | null = null
const toast = document.querySelector('.toast') as HTMLElement

function showToast(content: string) {
  if (toastTimer) return
  toast.innerText = content
  toast.className += ' active'
  toastTimer = setTimeout(function () {
    toast.className = 'toast'
    toastTimer = null
  }, 3000)
  setTimeout(() => {
    submitBtn.classList.remove('clicked')
  }, 200)
}

// 提交按钮
submitBtn.addEventListener('click', () => {
  submitBtn.classList.add('clicked')
  console.log(requiredInput)
  const parms: { [key: string]: string } = {
    Name: '',
    Eamil: '',
    Game: '',
    Link: '',
  }
  for (let i = 0; i < requiredInput.length; i++) {
    const type = requiredInput[i].getAttribute('data-type')
    if (!requiredInput[i].value || !type) {
      showToast(`${type} cannot be empty, please check the submitted information`)
      return
    } else {
      parms[type] = requiredInput[i].value
    }
  }
  submit(parms)
})

const submit = async (parms: { [key: string]: string }) => {
  try {
    const res = await apiUserShareGame({
      userName: parms.Name,
      userEmail: parms.Eamil,
      gameName: parms.Game,
      gameResourceUrl: parms.Link,
      gameInformation: textareaVal.value,
    })
    console.log('返回结果', res)
    // submitBtn.classList.remove('clicked')
    for (let i = 0; i < requiredInput.length; i++) {
      requiredInput[i].value = ''
      textareaVal.value = ''
    }
    showToast('submit success')
  } catch (error: any) {
    console.log('apiUserShareGame', error)
    showToast(error?.description?.desc || error?.message)
  }
}

function checkInputs() {
  const allInputsEmpty = Array.from(requiredInput).every(input => input.value.trim() === '') && textareaVal.value.trim() === ''
  if (allInputsEmpty) {
    submitBtn.classList.add('disabled')
  } else {
    submitBtn.classList.remove('disabled')
  }
}

requiredInput.forEach(input => {
  input.addEventListener('input', checkInputs)
})

textareaVal.addEventListener('input', checkInputs)

// 判断是否是移动端
const isMobile = () => {
  let result = false
  if (
    navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    )
  ) {
    result = true
  }
  return result
}

const toMobile = () => {
  if (isMobile()) {
    console.log('跳转移动端')
    location.href = `https://m.bikiigame.com/m${ENV === 'Product' ? '' : '/alpha'}/mobileBikiiHome/index.html`
  }
}

// 移动端访问 PC 端官网，跳转到移动端官网
toMobile()
