// 引入插件和语言包  vue-i18n@9.x 才支持vue3
import { createI18n } from 'vue-i18n'
import { getQueryString } from 'UTILS/utils'
import { LangPackage, MessagesData, Options, TranslationData } from 'UTILS/types/i18nUtils'
import { GetLanguageRes } from 'UTILS/types/utils'
import { kerkee } from 'LIBS/jsBridge'

function getLang(): Promise<string> {
  return new Promise((resolve, reject) => {
    let timer: NodeJS.Timeout | null
    if (!window.jsBridgeClient) reject('初始化jsBridgeClient失败')
    kerkee.getNativeData('getLanguage', {}, (data: GetLanguageRes) => {
      if (timer) {
        clearTimeout(timer)
        timer = null
      }
      console.log('从客户端获取系统语言', data.language)
      // 截取en-CN的en
      const language = data.language.split('-')[0]
      resolve(language)
    })
    timer = setTimeout(() => {
      // 获取不到返回空字符串
      reject('')
    }, 1000)
  })
}
// 注册国际化插件
// app.use(createI18n)

// 根据 langs 配置自动生成语言包
export function getYmlData(translationData: TranslationData): MessagesData {
  const messagesData: MessagesData = {}
  Object.entries(translationData).forEach(([key, val]) => {
    const langs = translationData.langs as Array<string>
    langs.forEach((lang: string) => {
      if (messagesData[lang] === undefined) {
        messagesData[lang] = {}
        messagesData[lang][key] = val[lang] || ''
      } else {
        messagesData[lang][key] = val[lang] || ''
      }
    })
  })
  console.log('配置包:', translationData)
  console.log('语言包:', messagesData)
  return messagesData
}

export async function getPageLanguage(order = ['CLIENT_LANG', 'SETUP_LANG', 'STORAGE_LANG', 'NAVIGATOR_LANG']): Promise<string> {
  const STORAGE_LANG_KEY = 'local'
  const CLIENT_LANG = await getLang().catch(err => console.log(err))
  const SETUP_LANG = getQueryString('language')
  const STORAGE_LANG = window.localStorage.getItem(STORAGE_LANG_KEY) || ''
  console.log(STORAGE_LANG)
  const NAVIGATOR_LANG = window.navigator.language !== 'en' ? window.navigator.language : ''
  const DEFAULT_LANG = 'en'
  const langPackage: LangPackage = {
    CLIENT_LANG: CLIENT_LANG,
    SETUP_LANG: SETUP_LANG,
    STORAGE_LANG: STORAGE_LANG,
    NAVIGATOR_LANG: NAVIGATOR_LANG,
  }
  console.log(`CLIENT_LANG:${CLIENT_LANG}; SETUP_LANG:${SETUP_LANG}; STORAGE_LANG:${STORAGE_LANG}; NAVIGATOR_LANG:${NAVIGATOR_LANG} `)
  for (const index of order) {
    if (langPackage[index]) {
      return langPackage[index] as string
    }
  }
  return DEFAULT_LANG
}

export async function getI18nInstance(options: Options) {
  const i18n = createI18n({
    legacy: false,
    locale: await getPageLanguage(options?.order),
    messages: getYmlData(options.data as TranslationData),
    fallbackLocale: 'en', // 添加默认
    silentFallbackWarn: true,
    silentTranslationWarn: true,
  })
  window.i18n = i18n

  return Promise.resolve(i18n)
}
