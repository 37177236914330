import axios, { AxiosError, AxiosResponse } from 'axios'
import adapterRequest from 'axios/lib/adapters/xhr'
import { INamespace, Root } from 'protobufjs'
import { VERSION, getKey, pcgoData } from 'UTILS/utils'
import { isAndroid, isInApp } from './constants'
import { getPageLanguage } from 'UTILS/i18nUtils'
import { Config, DecodeData, DecodeRes, HeadersObj, Setting } from './types/Axios'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { kerkee } from 'LIBS/jsBridge'
const fpPromise = FingerprintJS.load()
let root: Root

const Axios = axios.create({
  timeout: 10000,
  responseType: 'arraybuffer',
  headers: {
    'Content-Type': 'application/protobuf',
  },
})

// Add a request interceptor
Axios.interceptors.request.use(
  config => {
    // Do something before request is sent
    return config
  },
  err => {
    // Do something with request error
    return Promise.reject(err.data.error.message)
  }
)

Axios.interceptors.response.use(
  res => {
    // Do something with response data
    return res
  },
  err => {
    // Do something with response error
    return Promise.reject(err)
  }
)

// 因为pb文件的key和keyValue类型都要为string类型，统一转为字符串
// ps:上ts比较好发现类型错误
const formatValueToString = (data: any) => {
  const result = Object.create(null)
  Object.keys(data).forEach(key => {
    result[key] = `${data[key]}`
  })
  return result
}

export default async (settings: Setting): Promise<any> => {
  if (!root) {
    throw '请先设置pb'
  }

  // 在app内保证拿到version
  if (isInApp && !VERSION) {
    try {
      await getKey()
    } catch (err) {}
  }

  const defaults = {
    method: 'post',
    rpcInDesction: 'RPCInput',
    rpcOutDesction: 'RPCOutput',
  }
  const opts = Object.assign(defaults, settings)
  console.log('opts', opts, 'cpid', pcgoData?.cpid)
  const reqBuffer = encode(opts.reqDesction, opts.data || {})
  const client = isAndroid ? 'android' : 'ios'
  const rpcBuffer = encode(opts.rpcInDesction, {
    obj: opts.serverName,
    func: opts.funcName,
    req: reqBuffer,
    opt: formatValueToString({
      'X-Token': opts.token || '',
      // appid: IS_NET_GAME ? '7' : '',
      appid: '',
      client: `${client};h5;;${VERSION || '1.0.0'};${pcgoData?.versionCode || 0}`,
      cpid: pcgoData?.cpid || (await (await fpPromise).get()).visitorId,
      product_id: pcgoData?.productId || '',
      channel: pcgoData?.channel || '',
    }),
  })
  const headers: HeadersObj = {
    'X-Token': opts.token || '',
  }
  // chikii 判断 userAgent 添加本地语言请求头，手机端才有效果
  const isChikii = navigator.userAgent.toLowerCase().indexOf('chikii') > -1
  const isBikii = navigator.userAgent.toLowerCase().indexOf('bikii') > -1
  if (isChikii || isBikii) {
    const lang = await getPageLanguage()
    headers.Language = lang
    console.log('请求头设置 Language:', lang)
  }
  const waterFlowers = window.location.href.indexOf('waterFlowers') > -1
  if (waterFlowers && !headers.roomid) {
    kerkee.getNativeData('getPlayingRoomInfo', {}, (cb: Record<any, any>) => {
      const roomId = JSON.parse(cb.roomInfo).mRoomId
      headers.roomid = roomId
      console.log('浇花请求头设置 roomid', roomId)
    })
    // window.jsBridgeClient.getPlayingRoomInfo((cb: Record<any, any>) => {
    //   const roomId = JSON.parse(cb.roomInfo).mRoomId
    //   headers.roomid = roomId
    //   console.log('浇花请求头设置 roomid', roomId)
    // })
  }

  return new Promise((resolve, reject) => {
    // @ts-ignore
    Axios[opts.method](opts.url, rpcBuffer, {
      adapter: xhrAdapter,
      rpcBuffer,
      headers: headers,
    })
      .then((res: AxiosResponse) => {
        if (res.status !== 200) {
          reject({
            message: '发生错误',
            description: res.data.message,
          })
        } else {
          const rpcOut = decode(opts.rpcOutDesction, new Uint8Array(res.data)) as Record<string, any>
          if (rpcOut && rpcOut.ret === 0) {
            res.data = rpcOut.rsp ? decode(opts.resDesction, rpcOut.rsp) : {}

            resolve(res.data)
          } else {
            const errcode = rpcOut ? rpcOut.ret : 9002
            reject({
              message: '出现了点小错误(' + errcode + ')',
              description: rpcOut,
            })
          }
        }
      })
      .catch((err: AxiosError) => {
        reject({
          description: err,
          message: '接口异常',
        })
      })
  })
}

export const setPBName = (pbname: INamespace): Root => (root = Root.fromJSON(pbname))

// 生成消息体
const encode = (desction: string, data: Record<string, any>) => {
  // console.log('encode检测', root, 'pb文件检测', desction, 'pb文件检测')
  const dataDesction = root.lookupType(desction)
  const err = dataDesction.verify(data)

  if (err) {
    return false
  } else {
    const message = dataDesction.create(data)
    return dataDesction.encode(message).finish()
  }
}

// 解析响应体
export const decode = (desction: string, data: DecodeData): DecodeRes => {
  // console.log('decode检测', root, 'pb文件检测', desction, 'pb文件检测')
  const dataDesction = root.lookupType(desction)
  const err = dataDesction.verify(data)

  if (err) {
    return false
  } else {
    const res = dataDesction.decode(data as Uint8Array)
    return dataDesction.toObject(res, {
      defaults: true,
    })
  }
}

function xhrAdapter(config: Config) {
  config.data = config.rpcBuffer
  return adapterRequest(config)
}

export { root }
