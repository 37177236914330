import { bridge, getNativeMethod, callBackOption, registerOption, setNativeMethod, doActionMethod } from './bridgeInterface'
const browser = {
  versions: (function () {
    const u = navigator.userAgent,
      app = navigator.appVersion
    return {
      trident: u.indexOf('Trident') > -1, //IE
      presto: u.indexOf('Presto') > -1, //opera
      webKit: u.indexOf('AppleWebKit') > -1, //apple&google kernel
      gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //firfox
      mobile: !!u.match(/AppleWebKit.*Mobile.*/), //is Mobile
      flutter: u.indexOf('flutter-biki') > -1 || u.indexOf('flutter-joysak') > -1,
      // ipad13.3系统用这个有问题
      // ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //is ios
      ios: !!u.match(/Mac OS X/), //is ios
      android: u.indexOf('Android') > -1 || u.indexOf('Adr') > -1, //android
      iPhone: u.indexOf('iPhone') > -1, //iPhone or QQHD
      iPad: u.indexOf('iPad') > -1, //iPad
      iPod: u.indexOf('iPod') > -1, //iPod
      webApp: u.indexOf('Safari') == -1, //is webapp,no header and footer
      weixin: u.indexOf('MicroMessenger') > -1, //is wechat
      qq: u.match(/\sQQ/i)?.includes('qq'), //is qq
    }
  })(),
  language: (navigator.language).toLowerCase(),
}

let ApiBridge: bridge = {
  handleQueue: [], // 通知客户端JsBridge感知的队列
  msgQueue: [],
  callbackCache: [],
  callbackId: 0,
  processingMsg: false,
  isReady: false,
  isNotifyReady: false,
}
const bridgeCreate = function () {
  ApiBridge.bridgeIframe = document.createElement('iframe')
  ApiBridge.bridgeIframe.style.display = 'none'
  document.documentElement.appendChild(ApiBridge.bridgeIframe)
}
const prepareProcessingMessages = function () {
  ApiBridge.processingMsg = true
}
const fetchMessages = function () {
  if (ApiBridge.msgQueue.length > 0) {
    const messages = JSON.stringify(ApiBridge.msgQueue)
    ApiBridge.msgQueue.length = 0
    return messages
  }
  ApiBridge.processingMsg = false
  return null
}
const callNative = function (clz: string, method: string, args: any, callback?: any) {
  let msgJson = {
    clz,
    method,
    args,
  }
  if (args != undefined) msgJson.args = args
  if (callback) {
    const callbackId = getCallbackId()
    ApiBridge.callbackCache[callbackId] = callback
    if (msgJson.args) {
      msgJson.args.callbackId = callbackId.toString()
    } else {
      msgJson.args = {
        callbackId: callbackId.toString(),
      }
    }
  }

  if (browser.versions.ios || browser.versions.flutter) {

    if (ApiBridge.bridgeIframe == undefined) {
      bridgeCreate()
    }
    ApiBridge.msgQueue.push(msgJson)
    if (!ApiBridge.processingMsg) ApiBridge.bridgeIframe!.src = 'kcnative://go'

    window.initJSBridge = true
  } else if (browser.versions.android) {

    var ua = window.navigator.userAgent.toLowerCase()
    const isInApp = ua.match(/caiji|caijinetgame|chikii|kaiheiyun|magicpen|mitayun|bikii|hajimi|mogu|joysak/gi) !== null
    window.initJSBridge = true
    // android 
    return isInApp && prompt(JSON.stringify(msgJson))
  }
}
const log = function (msg: string) {
  callNative('ApiBridge', 'JSLog', {
    msg: msg,
  })
}
const getCallbackId = function (): number {
  return ApiBridge.callbackId++
}
/**
 * 
 * @param callbackId 
 * @param obj 
 * 客户端通知webviw的callback
 */
const onCallback = function (callbackId: number, obj: any) {
  if (ApiBridge.callbackCache[callbackId]) {
    ApiBridge.callbackCache[callbackId](obj)
  }
}
/**
 * 
 * @param options 
 * 执行JsBridge后，若想执行其他操作可使用
 * isHandleH5 是否是H5端调用
 */
const webviewCallback = function (options: callBackOption, isHandleH5?: boolean) {
  console.log('webviewCallback', options);
  isHandleH5 && callNative('ApiBridge', 'webviewCallback', options)
  /**
   * todo
   * 
   */
}

const webviewCallbackrRegister = function (options: registerOption) {
  console.log('options', options);
  ApiBridge.handleQueue.push(options)
}
// const testGetH5 = function (a: any, b: any) {      
//     console.log(a, b);
//     callNative('ApiBridge', 'webviewCallback', { event: 'ApiBridge.testGetH5', code: 0, msg: '', data: '' })
// }

const onBridgeInitComplete = function (callback: any) {
  callNative('ApiBridge', 'onBridgeInitComplete', {}, callback)
}

let _Configs = {
  isOpenJSLog: false,
  sysLog: {},
  isOpenNativeXHR: true,
  sysXHR: {},
}
const openJSLog = function () {
  _Configs.isOpenJSLog = true
  // global.console.log = ApiBridge.log
}
const closeJSLog = function () {
  _Configs.isOpenJSLog = false
  // global.console.log = _Configs.sysLog
}
/**
 * getNativeData(method:string,params:{},callback) 从客户端获取数据
 * 参数详情请看 https://confluence.caijiyouxi.com/pages/viewpage.action?pageId=11763717
 */
const getNativeData = function <T extends keyof getNativeMethod>(method: T, params: Parameters<getNativeMethod[T]>[0], callback: any) {
  const pms = params || {}
  callNative('jsBridgeClient', method, pms, callback)
}
/**
 * setNativeData(method:string,params:{key:value})H5告诉客户端一些数据 ,客户端执行相应操作
 * 参数详情请看 https://confluence.caijiyouxi.com/pages/viewpage.action?pageId=11763717
 */
const setNativeData = function <T extends keyof setNativeMethod>(method: T, params: any) {
  const pms = params || {}
  callNative('jsBridgeClient', method, pms)
}

/**
 * doAction(method:string,params:{})H5调用客户端组件或方法
 * 参数详情请看 https://confluence.caijiyouxi.com/pages/viewpage.action?pageId=11763717
 */
const doAction = function <T extends keyof doActionMethod>(method: T, params?: any, callback?: any) {
  const pms = params || {}
  callNative('jsBridgeClient', method, pms, callback)
}

export {
  browser,
  ApiBridge,
  _Configs,
  bridgeCreate,
  getNativeData,
  setNativeData,
  // webviewCallbackrRegister,
  webviewCallback,
  doAction,
  prepareProcessingMessages,
  fetchMessages,
  log,
  getCallbackId,
  onCallback,
  onBridgeInitComplete,
  openJSLog,
  closeJSLog,
  // testGetH5,
}
