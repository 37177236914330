import parseQueryString from 'outils/parseQueryString'
import { InitPcGoDataRes, ParseQueryString, PCGOdata } from 'UTILS/types/utils'
import { kerkee } from 'LIBS/jsBridge'

/**
 * 获取URL参数
 * @param name
 * unescape 已废除
 * https://developer.mozilla.org/zh-CN/docs/Web/JavaScript/Reference/Global_Objects/unescape
 */
function getQueryString(name: string): string | null {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  // console.log(reg, 'reg')
  let r = window.location.search?.substr(1).match(reg)
  if (r != null) return decodeURIComponent(r[2])

  const hash = window.location.hash
  if (hash.indexOf('?') > 0) {
    const searchInHash = hash.substr(hash.indexOf('?') + 1)
    r = searchInHash.match(reg)
    if (r != null) return decodeURIComponent(r[2])
  }
  return null
}

const query: ParseQueryString = parseQueryString(location.search)

let KEY = query.token || ''
let pcgoData: PCGOdata
let VERSION = ''
let NEWVERSION = false // 新版本判断  4.0.0及以上是新版本
// 本地调试
if (localStorage.getItem('pcgoData')) {
  pcgoData = JSON.parse(localStorage.getItem('pcgoData') as string)
  KEY = pcgoData.loginKey
}

// 从客户端获取系统语言
function getKey(): Promise<string> {
  return new Promise((resolve, reject) => {
    let timer: NodeJS.Timeout | null
    if (pcgoData?.loginKey) {
      KEY = pcgoData.loginKey
      return resolve(KEY)
    }
    if (KEY) return resolve(KEY)
    if (!window.initJSBridge) return reject('初始化jsBridgeClient失败')
    kerkee.getNativeData('initPcGoData', {}, (data: InitPcGoDataRes) => {
      console.log('---initPcGoData---', data)
      if (timer) {
        clearTimeout(timer)
        timer = null
      }
      if (data) {
        VERSION = data.versionName || data.versionCode || ''
        // 新旧版本判断 4.0.0及以上使用斜角按钮，旧版保持不变
        NEWVERSION = checkVersion(VERSION, '4.0.0')
        data.id2 = ~~data.id2
        KEY = data.loginKey
        pcgoData = data
        kerkee.doAction('logInfo', { status: 'success', log_tag: 'initPcGoData-success', log_msg: `${data.loginKey}` })
        resolve(data.loginKey)
      } else {
        reject('请先登录')
        kerkee.doAction('logInfo', { status: 'fail', log_tag: 'initPcGoData-error', log_msg: JSON.stringify(data) })
      }
    })

    timer = setTimeout(() => {
      console.log('initPcGoData执行失败, 超时变为2s')
      reject('请先登录')
      window.jsBridgeClient.doAction('logInfo', { status: 'fail', log_tag: 'initPcGoData-timeOut', log_msg: 'initPcGoData执行失败,方法超时' })
    }, 2000)
  })
}

/**
 * 检查版本
 * @param {*} current 当前版本
 * @param {*} min 最低版本
 * @param {*} equal true为大于或等于，false为大于
 */
function checkVersion(current: string, min: string, equal = true): boolean {
  if (!current || !min) return false
  const currentArr = current.split('.')
  const minArr = min.split('.')
  if (Array.isArray(currentArr) && Array.isArray(minArr) && currentArr.length > 0 && minArr.length > 0) {
    const maxLen = currentArr.length < minArr.length ? currentArr.length : minArr.length
    for (let i = 0; i < maxLen; i++) {
      const curentItem = parseInt(currentArr[i]) || 0
      const minItem = parseInt(minArr[i]) || 0
      if (curentItem < minItem) {
        return false
      } else if (curentItem > minItem) {
        return true
      }
    }
    return equal
  }
  return false
}

/**
 * 节流函数就是只允许一个函数在一定时间内执行一次,适用于高频率事件。
 * @param  {Function} fn                [执行函数]
 * @param  {Number}   [interval=1000]       [间隔时间]
 * @return {[type]}                   [function]
 */
// eslint-disable-next-line @typescript-eslint/ban-types
function throttle(fn: Function, interval = 2000): Function {
  let timer: NodeJS.Timeout
  let preTime = +new Date()
  return function () {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this
    // eslint-disable-next-line prefer-rest-params
    const args = arguments
    const curTime = +new Date()
    if (timer) clearTimeout(timer)
    // 超过间隔时间执行,并记录当前时间是新的时间点
    if (curTime - preTime >= interval) {
      preTime = curTime
      // eslint-disable-next-line prefer-rest-params
      fn.apply(self, arguments)
    } else {
      // 间隔时间内设置定时器,保证即使停止操作也至少执行一次
      timer = setTimeout(() => {
        fn.apply(self, args)
      }, interval)
    }
  }
}

// 补充 pcgo 项目中用到 SCHEMA 作为协议头
const SCHEMA = 'pcgo'

export { getQueryString, getKey, KEY, VERSION, NEWVERSION, pcgoData, SCHEMA, checkVersion, throttle }
