import { bridge, jsBridgeClient } from './bridgeInterface'
import {
  _Configs,
  ApiBridge,
  bridgeCreate,
  // webviewCallbackrRegister,
  prepareProcessingMessages,
  webviewCallback,
  fetchMessages,
  getNativeData,
  setNativeData,
  doAction,
  log,
  onCallback,
  onBridgeInitComplete,
  // testGetH5,
  openJSLog,
  closeJSLog
} from './nativeBridge'
export let apiBridge: bridge = Object.assign({
  bridgeCreate,
  prepareProcessingMessages,
  fetchMessages,
  log,
  onBridgeInitComplete,
  onCallback,
  webviewCallback,
  // testGetH5,
}, ApiBridge)

export let kerkee: jsBridgeClient = {
  getNativeData,
  setNativeData,
  doAction,
  webviewCallback,
  // webviewCallbackrRegister,
};
(function (window) {
  let global = window
  global.ApiBridge = apiBridge
  global.jsBridgeClient = kerkee
  onBridgeInitComplete(function (aConfigs: any) {
    if (aConfigs) {
      if (aConfigs.hasOwnProperty('isOpenJSLog')) {
        _Configs.isOpenJSLog = aConfigs.isOpenJSLog
      }
      if (aConfigs.hasOwnProperty('isOpenNativeXHR')) {
        _Configs.isOpenNativeXHR = aConfigs.isOpenNativeXHR
      }
    }
  })
})(window)
